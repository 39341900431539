<!--
 * @Description: 客户联系人
 * @Author: ChenXueLin
 * @Date: 2021-10-21 11:33:29
 * @LastEditTime: 2021-11-24 09:40:43
 * @LastEditors: ChenXueLin
-->

<template>
  <div>
    <!-- 搜索头部 start -->
    <table-title>
      <template v-slot:searchForm>
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="姓名"
              title="姓名"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="电话"
              title="电话"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="地址"
              title="地址"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="orderNo">
            <el-input
              v-model="searchForm.orderNo"
              placeholder="邮箱"
              title="邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:operateAction>
        <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
        <!-- <i class="e6-icon-Import_fill" title="导入"></i> -->
        <i class="e6-icon-export_fill" title="导出"></i>
      </template>
    </table-title>
    <!-- 搜索头部 end -->

    <!-- 表格 start -->
    <section class="table-wrapper">
      <el-table border height="300px" :data="tableData" highlight-current-row>
        <!-- <el-table-column type="selection" width="40"></el-table-column> -->
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'chargeCode'">
              <el-button type="text" @click="toDetail(row)">
                {{ row[column.fieldName] }}
              </el-button>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column> -->
      </el-table>
    </section>
    <!-- 表格 end -->

    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <!-- 新增弹框 -->
    <add-concat-dialog
      :isNeedReq="true"
      :addContactVisible="addContactVisible"
      @handleClose="handleClose"
    ></add-concat-dialog>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import addConcatDialog from "@/components/addConcatDialog.vue";
export default {
  components: { addConcatDialog },
  name: "workOrderList",
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      total: 0,
      columnData: [
        {
          fieldName: "chargeCode",
          display: true,
          fieldLabel: "姓名",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "chargeTypeCodeName",
          display: true,
          fieldLabel: "邮箱",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "chargeTypeCodeName",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          chargeCode: "11111"
        },
        {
          chargeCode: "155551"
        }
      ], // 表格数据
      addContactVisible: false //添加联系人
    };
  },
  mixins: [base, listPage, listPageReszie],

  computed: {},

  mounted() {},

  methods: {
    //点击详情
    toDetail() {
      this.routerPush({
        name: "customerManage/customerDetail",
        params: {
          refresh: true
        }
      });
    },
    //点击新增
    handleAdd() {
      this.addContactVisible = true;
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
